<script>


/**
 * Basic Info Edit component
 */



import Choices from "choices.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import simplebar from "simplebar-vue";


import { getArApApi , getCashApi} from '@/api/cash'
import { financial } from '@/api/misc'
import {
    required,
} from "vuelidate/lib/validators";

export default {

    emits: ['confirm', 'cancel'],

    props: ['bills'],
    components: {
        flatPickr,
        simplebar
    },

    validations: {
        post_date : {required},
        run_date : {required}
    },



    data() {

        return {
            bank_account_id : '',
            bank_account_list : [],
            bank_account_choice : {},
            amount : 0,
            desc : '',
            run_date : '',
            post_date : '',
        }

    },
    methods: {
        f(v, d=2) {
            return financial(v, d)
        },



        formSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid == false) {
                let agent_bills = []
                this.bills.map(b => agent_bills.push({id : b.id, agent_id : b.agent.id}))
                let data = {
                    agent_bills     : agent_bills,
                    bank_account_id : this.bank_account_id,
                    remarks         : this.desc,
                    charge_type     : 'PAD',
                    post_date       : this.post_date,
                    run_date        : this.run_date
                }
                getArApApi().setup_agent_bill_charge_file(data).then((res) => {
                    if (res.errCode == 0) {
                        this.$emit('confirm')
                    } else {
                        this.$alertify.error("Error  "+ res.errCode+" "+res.msg);
                    }
                })
            }
        },


    },

    created() {


    },

    mounted() {
        this.bank_account_choice = new Choices('#choices-bank-account', {})

        getCashApi().bank_account_list().then((res) => {
            if (res.errCode == 0) {
                this.bank_account_list = []
                res.data.accounts.map((b) => {
                    this.bank_account_list.push({
                        label : b.name,
                        value : b.id,
                        bank_account : b,
                        selected : b.account_type =='bankAccount_type4'? true : false
                    })
                })
                let general_account = res.data.accounts.find(e =>e.account_type =='bankAccount_type4')
                this.bank_account_id = general_account.id
                this.bank_account_choice.setChoices(this.bank_account_list)
            }
        })

       
        if (this.bills) {
            this.amount = this.f(this.bills.reduce((a, c) => a += c.amount + c.tax, 0))
        }
        

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">

                            <label for="choices-bank-account" class="form-label">Bank Account</label>
                            <select class="form-control" v-model="bank_account_id" name="choices-bank-account" id="choices-bank-account" :disabled="true">
                            </select>

                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="validationCustom01">Amount</label>
                            <input id="validationCustom01" v-model="amount" type="text" class="form-control"
                                placeholder="$"  readonly />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom01">Post Date</label>
                             <flat-pickr   v-model="post_date"  placeholder="Select a date"   class="form-control"  :class="{'is-invalid':  $v.post_date.$error }"  ></flat-pickr>
                            <div v-if=" $v.post_date.$error" class="invalid-feedback">
                                <span v-if="!$v.post_date.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom01">Run date</label>
                            <flat-pickr   v-model="run_date"  placeholder="Select a date"   class="form-control"  :class="{'is-invalid':  $v.run_date.$error }"  ></flat-pickr>
                            <div v-if=" $v.run_date.$error" class="invalid-feedback">
                                <span v-if="!$v.run_date.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row-->


                <div class="row">
                    <div class="col-md-12">
                        <div class="mb-3">
                            <label for="validationCustom01">Description</label>
                            <input id="validationCustom01" v-model="desc" type="text" class="form-control"
                                placeholder="" value="" />
                        </div>
                    </div>
                </div>
                <!-- end row-->


                <simplebar class="table-container" data-simplebar-auto-hide="false">
                    <div class="table-responsive" style="max-height: 400px;">
                        <div class="table align-middle table-nowrap" style="height:100%" >
                            <b-table :items="bills" :fields="['Amount', 'Agent','Invoice']" responsive="sm" :per-page="bills.length" 
                            class="table-check">

                                <template #cell(Amount)="data">
                                    ${{ (data.item.amount + data.item.tax).toLocaleString() }}
                                </template>
                                <template #cell(Agent)="data">
                                    {{   data.item.agent_name  }}
                                </template>
                                <template #cell(Invoice)="data">
                                    {{   data.item.invoice_number  }}
                                </template>
                            </b-table>
                        </div>
                    </div>
                </simplebar>

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>