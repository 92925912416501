<script>


/**
 * Deposit Edit component
 */



 import Choices from "choices.js";

import "vue2-dropzone/dist/vue2Dropzone.min.css";

import { getCashApi,getArApApi } from '@/api/cash'

export default {

    emits: ['confirm', 'cancel'],
    props: ['bills'],
   
    components: {
       
    },

    validations: {
        
    },

    

    data() {
        return {
            submitted : false,
            file_validate : true,
            file : {

            },
            cheque_number :'',
            amount : '',
           
            bank_account_id : '',
            desc : '',
         }
    },

    methods: {
        formSubmit() {
           this.submitted = true
            this.$v.$touch();
           
            let agent_bills  = []
            this.bills.map(b => agent_bills.push({id : b.id, agent_id : b.agent.id}))
            let data = {
                cheque_file : this.file,
                agent_bills : agent_bills,
                general_bank_id : this.bank_account_id,
                remarks : this.desc
            }
            getArApApi().deposit_agent_bill_etransfer(data).then((res) => {
                if (res.errCode == 0) {
                    this.$alertify.message("Charge Suucessfully  ");
                    this.$emit('confirm')
                } else {
                    this.$alertify.error("Error  "+ res.errCode);
                }
            })

            this.$emit('confirm', data)
            
        },

        fileUploaded(ev, resp) {
            
            if (resp[0].errCode == 200) {
                this.file_validate = false
                this.file = {
                    save_route: resp[0].response.url,
                    save_route_key: resp[0].response.key,
                    file_name :resp[0].response.filename,
                    size : resp[0].response.size,
                    type : resp[0].response.type
                }
            } else {
                this.file_validate = true
            }
        },

    },

    created() {
      
    },

    mounted() {

        this.bank_account_choice = new Choices('#choices-bank-account', {})

        getCashApi().bank_account_list().then((res) => {
            if (res.errCode == 0) {
                this.bank_account_list = []
                res.data.accounts.map((b) => {
                    this.bank_account_list.push({
                        label : b.name,
                        value : b.id,
                        bank_account : b,
                        selected : b.account_type =='bankAccount_type4'? true : false
                    })
                })
                let general_account = res.data.accounts.find(e =>e.account_type =='bankAccount_type4')
                this.bank_account_id = general_account.id
                this.bank_account_choice.setChoices(this.bank_account_list)
            }
        })


        if (this.bills) {
            this.amount = this.bills.reduce((a, c) => a += c.amount + c.tax, 0)
        }
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">

                <div class="row">
                   

                   <div class="col-xl-4">
                       <div class="mb-3">
                        <label for="choices-bank-account" class="form-label">Bank Account</label>
                            <select class="form-control" v-model="bank_account_id" name="choices-bank-account" id="choices-bank-account" :disabled="true">
                            </select>
                       </div>
                   </div>

                   <div class="col-xl-4">
                       <div class="mb-3">
                           <label for="formFileSm" class="form-label"
                               >Cheque Amount</label
                           >
                           <input id="validationCustom01" v-model="amount" type="text" class="form-control"
                                placeholder="$"  readonly />
                       </div>
                   </div>
               </div>
               <!-- end row-->

               <div class="row">
                    <div class="col-md-12">
                        <div class="mb-3">
                            <label for="validationCustom01">Description</label>
                            <input id="validationCustom01" v-model="desc" type="text" class="form-control"
                                placeholder="" value="" />
                        </div>
                    </div>
                </div>
                <!-- end row-->

             
                

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Confirm</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>